import React, { useState } from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Header from "../components/header"
import Footer from "../components/footer"
import Socials from "../components/socials"
import SEO from "../components/SEO"
import styles from "./blogTemplate.module.scss"
import Carousel from "nuka-carousel"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { Lightbox } from "react-modal-image"

let slideToShowNumber = 3
if (typeof window !== `undefined`) {
  if (window.innerWidth < 768) slideToShowNumber = 1
}

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark

  var [open, setOpen] = useState(false)
  var [imageSrc, setimageSrc] = useState("")
  var [imageAlt, setimageAlt] = useState("")

  function openPopup(e) {
    setimageSrc(e.currentTarget.getAttribute("data-src"))
    setimageAlt(e.currentTarget.getAttribute("data-alt"))
    setOpen(true)
  }

  function closePopup(e) {
    setOpen(false)
  }

  return (
    <div>
      <SEO postData={markdownRemark} pageImage={frontmatter.photo ? frontmatter.photo.childImageSharp.fluid.src : null} />
      <Header />
      {frontmatter.photo && (
        <Img
          fluid={frontmatter.photo.childImageSharp.fluid}
          className="featured"
          objectFit="cover"
          objectPosition="50% 50%" 
        />
      )}
      <div className={styles.block}>
        <Row>
          <Col>
            <h1>{frontmatter.title}</h1>
            <div
              className="blog-post-content"
              dangerouslySetInnerHTML={{ __html: html }}
            />
            {frontmatter.gallery && frontmatter.gallery.length > 0 && (
              <Carousel
                slidesToShow={slideToShowNumber}
                withoutControls={false}
                cellSpacing={20}
                wrapAround={true}
                renderBottomCenterControls={false}
              >
                {frontmatter.gallery.map(({ image, title, alt }, index) => (
                  <div onClick={openPopup}
                    onKeyPress={openPopup}
                    role="button"
                    tabIndex={index}
                    data-src={image.childImageSharp.fluid.src}
                    data-alt={alt}
                  >
                    <Img
                      fluid={image.childImageSharp.fluid}
                      className={styles.carouselImage}                    
                    />
                  </div>                  
                ))}
              </Carousel>
            )}
          </Col>
        </Row>
      </div>
      {open && (
        <Lightbox
          large={imageSrc}
          alt={imageAlt}
          onClose={closePopup}
          hideDownload
          hideZoom
        />
      )}
      <Socials />
      <Footer />
    </div>
  )
}
export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      excerpt(pruneLength: 150)
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title        
        photo {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        gallery {
          alt
          title
          image {
            childImageSharp {
              fluid(maxWidth: 900) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
